(function() {
    'use strict';

  var detectedOS = avastGlobals.detect.os.name.toLowerCase(),
    $window = $(window),
    $buttons = $('.sticky-bar a'),
    waypoints = avm.require('avast.web.waypoints'),
    platformDetectionSelector = '#sticky-bar-platform-detect'

  if ($(platformDetectionSelector).length) {
    var app = new Vue({
      el: platformDetectionSelector,
      delimiters: ["<%", "%>"],
      data: {
        detectedOS: detectedOS,
      },
      mounted: function () {
        $buttons = $('.sticky-bar a');
        if ($('.sticky-bar').length) {
          $window.on('resize scroll', handleWindowChange);
        }

        if ($buttons.length) {
          $buttons.attr("tabindex",-1);

          $buttons.filter('[href^="#"]').on('click', handleButtonsClick);
        }

        // handle custom hashtag platform
        if (window.location.hash) {
          changeDetectedOs(this);
        }
        window.addEventListener('hashchange', function() {
          changeDetectedOs(this);
        })
      },
    })
  } else {
    if ($('.sticky-bar').length) {
      $window.on('resize scroll', handleWindowChange);
    }

    if ($buttons.length) {
      $buttons.attr("tabindex",-1);

      $buttons.filter('[href^="#"]').on('click', handleButtonsClick);
    }
  }

  function handleWindowChange() {
    if (waypoints($('.sticky-waypoint'), null, null, true)) {
      $('.sticky-bar').addClass('is-sticky');
      $buttons.attr("tabindex",0);
    } else {
      $('.sticky-bar').removeClass('is-sticky');
      $buttons.attr("tabindex",-1);
    }
  }

  function handleButtonsClick(e) {
    e.preventDefault()
    var targetSection = $($(this).attr('href'))
    $('html, body').animate({
      scrollTop: targetSection.length ? targetSection.offset().top : 0,
    })
  }

  function changeDetectedOs(thisApp) {
    var changedHash = window.location.hash.substring(1),
        newOS = detectedOS;
    if (changedHash === 'pc') {
      newOS = 'windows';
    } else if (changedHash === 'mac' || changedHash === 'ios' || changedHash === 'android') {
      newOS = changedHash;
    }
    if (app && app._data && app._data.detectedOS) {
      app._data.detectedOS = newOS;
    } else if (thisApp && thisApp._data && thisApp._data.detectedOS) {
      thisApp._data.detectedOS = newOS;
    }
  }

})();
